<template>
	<div class="item">
		<h1 class="title">Collections</h1>

		<div class="links">
			<MenuLink
				:item-bold="true"
				link="/collections"
			>
				{{ $t("components.navigations.label.all_collections") }}
			</MenuLink>
			<MenuLink
				v-for="item in props.collections"
				:key="item.id"
				@click="navigateTo(`/${orgSlug}/collections/${item.id}`)"
			>
				{{ item.name }}
			</MenuLink>
		</div>
	</div>
</template>
<script setup lang="ts">
import MenuLink from "@/components/Navigation/Submenu/MenuLink.vue";
import type { CatalogCollection } from "@/types/products";

interface SubmenuProps {
	collections: CatalogCollection[];
	orgSlug: string | undefined;
}
const props = defineProps<SubmenuProps>();
</script>
<style lang="scss" scoped>
.title {
	@apply px-[20px];
}
.links {
	@apply border-t-[1px] border-neutral-100 pt-[25px] px-[20px];
}
</style>
