<template>
	<div class="item">
		<h1 class="title">Language</h1>

		<div class="links">
			<MenuLink
				v-for="item in languages"
				:key="item.id"
			>
				<div class="lang_options">
					<span class="lang_label">{{ $t(item.label) }}</span>
					<Checkbox
						v-model="item.value"
						class="mt-[1px]"
					/>
				</div>
			</MenuLink>
		</div>
	</div>
</template>
<script setup lang="ts">
import MenuLink from "@/components/Navigation/Submenu/MenuLink.vue";
import Checkbox from "@/components/Checkbox.vue";

const languages = [
	{
		id: "english",
		label: "components.navigations.label.english",
		value: true,
	},
	{
		id: "french",
		label: "components.navigations.label.french",
		value: false,
	},
];
</script>
<style lang="scss" scoped>
.title {
	@apply px-[20px];
}
.links {
	@apply border-t-[1px] border-neutral-100 pt-[25px] px-[20px];

	.lang_options {
		@apply flex h-[40px] leading-[40px];
		.lang_label {
			@apply w-[25%];
		}
	}
}

.v-input {
	:deep(.v-input__details) {
		@apply hidden;
	}
}
</style>
