<template>
	<div
		v-if="!showingSubcategory"
		class="item"
	>
		<h1 class="title">Products</h1>

		<div class="links">
			<MenuLink
				:item-bold="true"
				@click="navigateTo(`/${props.orgSlug}/products`)"
			>
				{{ $t("components.navigations.label.all_products") }}
			</MenuLink>
			<MenuLink
				v-for="item in props.categories"
				:key="item.id"
				:subitem="item.subcategories.length > 0"
				@click="categoryAction(item)"
			>
				{{ item.text }}
			</MenuLink>
		</div>
	</div>
	<div
		v-else
		class="subcategory"
	>
		<div class="item">
			<h1 class="title">{{ parentName }}</h1>

			<div class="links">
				<MenuLink
					v-for="item in subcategories"
					:key="item.id"
					@click="navigateTo(`/${props.orgSlug}/products?category=${item.id}`)"
				>
					{{ item.text }}
				</MenuLink>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
import MenuLink from "@/components/Navigation/Submenu/MenuLink.vue";
import type { Category } from "@/types/products";

interface SubmenuProps {
	categories: Category[];
	orgSlug: string | undefined;
}
const props = defineProps<SubmenuProps>();
const showingSubcategory = ref(false);
const parentName = ref("");
const subcategories = ref<Category[]>([]);

const categoryAction = (item: Category) => {
	if (item.subcategories.length > 0) {
		parentName.value = item.text;
		subcategories.value = item.subcategories;
		showingSubcategory.value = true;
	} else {
		navigateTo(`/${props.orgSlug}/products?category=${item.id}`);
	}
};
</script>
<style lang="scss" scoped>
.title {
	@apply px-[20px];
}
.links {
	@apply border-t-[1px] border-neutral-100 pt-[25px] px-[20px];
}

.subcategory {
	@apply cursor-auto text-black px-[40px] pt-[8px] overflow-auto h-full divide-y-[1px] divide-neutral-200;
	@apply mobile:px-0;
	:deep(.item) {
		@apply py-[32px] mobile:py-[20px];
		.title {
			@apply text-[24px] leading-[32px] font-bold mb-[8px] px-[20px];
		}
		.subtitle {
			@apply text-[20px] leading-[30px];
		}
		.description {
			@apply text-[14px] leading-[20px] text-neutral-700;
		}
		.links {
			@apply flex flex-col mt-[16px] space-y-[25px] border-t-[1px] border-neutral-100 pt-[25px] px-[20px];
		}
		.action {
			@apply cursor-pointer text-blue-200 text-[14px] leading-[20px];
		}
	}
}
</style>
