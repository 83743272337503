<template>
	<v-app-bar elevation="0">
		<TopBar />
		<NavBar />
	</v-app-bar>
	<MenuMobile
		:active-submenu="activeSubmenu"
		:active-subcategory="activeSubcategory"
		@toggle-submenu="value => (activeSubmenu = value)"
		@toggle-subcategory="value => (activeSubcategory = value)"
	/>
</template>
<script lang="ts" setup>
import $bus from "../../bus";
import TopBar from "./TopBar.vue";
import NavBar from "./NavBar.vue";
import MenuMobile from "@/components/TngvNavigation/MenuMobile.vue";

const activeSubmenu = ref<string | null>(null);
const activeSubcategory = ref<string | null>(null);

onMounted(() => {
	$bus.on("toggleSubcategory", category => {
		activeSubcategory.value = category as string;
	});
});
</script>
<style lang="scss" scoped>
.v-toolbar {
	@apply h-[102px] mobile:h-[120px] mobile:w-full border-b border-neutral-100;
	max-width: 100vw;
}
</style>
