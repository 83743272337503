<template>
	<div class="nav_bar_wrapper">
		<div class="nav_bar">
			<div class="nav_bar_content">
				<div class="left_items flex_grow">
					<Icon
						id="menu_icon"
						name="md-menu"
						@click="toggleMenuMobile"
					/>
					<div class="left_content">
						<v-menu
							offset="17"
							:close-on-content-click="true"
						>
							<template #activator="{ props }">
								<Button
									v-bind="props"
									variant="flat"
									rounded="xl"
									class="-ml-[13px]"
								>
									<Icon
										name="md-menu"
										class="mr-[8px]"
									/>
									<span>Products</span>
								</Button>
							</template>
							<v-card
								class="mx-auto main_menu_card"
								width="600"
								@mouseleave="showSubcategory(null)"
							>
								<v-list v-model:opened="open">
									<v-list-item
										class="font-bold"
										@click="navigateTo('/' + organizationProfile?.slug + '/products')"
										@mouseover="showSubcategory(null)"
										>All products
									</v-list-item>
									<!-- <v-list-item
										v-for="item in data?.categories"
										:key="item.id"
										@click="navigateTo('/' + organizationProfile?.slug + '/products?category=' + item.id)"
										@mouseover="showSubcategory(item)"
									>
										{{ $t(item.text) }}
									</v-list-item> -->
								</v-list>
								<div class="subcategory">
									<v-list-item
										v-for="item in activeSubcategories"
										:key="item.id"
										@click="navigateTo('/' + organizationProfile?.slug + '/products?category=' + item.id)"
									>
										{{ item.text }}
									</v-list-item>
								</div>
							</v-card>
						</v-menu>
					</div>
					<!-- <div class="left_content">
						<v-menu offset="17">
							<template #activator="{ props }">
								<Button
									v-bind="props"
									variant="flat"
									rounded="xl"
								>
									<Icon
										name="md-grid_view"
										class="mr-[8px]"
									/>
									<span id="desktop_text">Collections</span>
								</Button>
							</template>
							<v-list>
								<v-list-item
									class="text-neutral-900 font-bold"
									@click="navigateTo('/' + organizationProfile?.slug + '/collections')"
									>All collections</v-list-item
								>
								<v-list-item
									v-for="collection in data?.collections"
									:key="collection.id"
									@click="navigateTo('/' + organizationProfile?.slug + '/collections/' + collection.id)"
								>
									{{ collection.name }}
								</v-list-item>
							</v-list>
						</v-menu>
					</div> -->
					<!-- <div class="text-neutral-500 left_content">
						<v-menu offset="17">
							<template #activator="{ props }">
								<Button
									v-bind="props"
									variant="flat"
									rounded="xl"
								>
									<Icon
										name="md-search"
										class="mr-[8px]"
									/>
									<span id="desktop_text">Search</span>
								</Button>
							</template>
							<v-card min-width="730">
								<v-list>
									<v-list-item>
										<SearchInput
											v-model="searchInput"
											placeholder="Search by name..."
											clearable
											class="search_input mr-[8px] mobile:mr-0"
											@update:model-value="onSearchUpdate"
										/>
									</v-list-item>
								</v-list>

								<div class="px-[24px] mb-[12px] text-[16px] font-bold text-neutral-900">
									Search results is disabled for now.<span class="text-[12px] font-normal text-neutral-700"
										>({{ searchResults.length }})</span
									>
								</div>
								<v-divider></v-divider>
								<v-list id="search_result_list">
									<v-list-item
										v-for="(item, index) in searchResults"
										:key="index"
									>
										<div class="result_item">
											<div class="image_name">
												<div
													class="image"
													:style="{ 'background-image': `url(${item.imageUrl})` }"
												></div>
												<div class="name">
													<span class="font-bold">DTY </span>
													{{ item.name }}
												</div>
											</div>
											<div class="price">
												<span>${{ item.price }} </span><span class="text-[12px]">USD</span>
											</div>
										</div>
										<v-divider></v-divider>
									</v-list-item>
								</v-list>

								<div class="px-[24px] h-[48px] leading-[48px] text-blue-200 mt-[-10px]">View all results</div>
							</v-card>
						</v-menu>
					</div> -->
				</div>
				<div class="middle_items flex_grow">
					<NuxtLink :to="{ name: 'org_slug', params: { org_slug: organizationProfile?.slug } }">
						<img
							v-if="logoUrl !== ''"
							:src="logoUrl"
							class="max-h-[70px]"
						/>
						<div v-else>
							<span class="org_title">{{ organizationProfile?.public_name }}</span>
						</div>
					</NuxtLink>
				</div>
				<div class="right_items flex_grow">
					<div class="right_content">
						<AccountMenu />
					</div>

					<NuxtLink
						class="flex items-center gap-1 cursor-pointer"
						:to="{
							name: 'org_slug-my-cart',
							params: { org_slug: $route.params.org_slug },
						}"
					>
						<Icon
							name="md-local_mall"
							class="mr-1"
						/>
						<Badge
							v-if="numOfTextileItems !== null"
							:visible="true"
							:content="numOfTextileItems as number ?? 0"
							:color="numOfTextileItems as number > 0 ? 'red' : '#6E6E73'"
							class="-mt-[20px] mr-[7px]"
						/>
						<span class="translate-y-[2px]">Cart</span>
					</NuxtLink>
				</div>
			</div>

			<!-- mobile search -->
			<!-- <div class="mobile_search">
				<v-menu offset="17">
					<template #activator="{ props }">
						<SearchInput
							v-model="searchInput"
							v-bind="props"
							placeholder="Search by name..."
							clearable
							class="search_input mr-[8px] mobile:mr-0"
							@update:model-value="onSearchUpdate"
						/>
					</template>
					<v-card
						min-width="450"
						class="mt-[-10px]"
					>
						<div class="mt-4 px-[24px] mb-[12px] text-[16px] font-bold text-neutral-900">
							Search results is disabled for now.<span class="text-[12px] font-normal text-neutral-700"
								>({{ searchResults.length }})</span
							>
						</div>
						<v-divider></v-divider>
						<v-list id="search_result_list">
							<v-list-item
								v-for="(item, index) in searchResults"
								:key="index"
							>
								<div class="result_item">
									<div class="image_name">
										<div
											class="image"
											:style="{ 'background-image': `url(${item.imageUrl})` }"
										></div>
										<div class="name">
											<span class="font-bold">DTY </span>
											{{ item.name }}
										</div>
									</div>
									<div class="price">
										<span>${{ item.price }} </span><span class="text-[12px]">USD</span>
									</div>
								</div>
								<v-divider></v-divider>
							</v-list-item>
						</v-list>

						<div class="px-[24px] h-[48px] leading-[48px] text-blue-200 mt-[-10px]">View all results</div>
					</v-card>
				</v-menu>
			</div> -->
		</div>
	</div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import $bus from "../../bus";
import Badge from "../Badge.vue";
// import SearchInput from "../Input/SearchInput.vue";
import Button from "../Button.vue";

import type { Category } from "@/types/products";
import AccountMenu from "@/components/Navigation/AccountMenu.vue";
// const { listCategoriesForShowroom, listCollections } = usePM();

const cartStore = useCartStore();
const { numOfTextileItems } = storeToRefs(cartStore);
const $route = useRoute();
const orgStore = useOrgStore();

const { organizationProfile } = storeToRefs(orgStore);

// Collections
const activeSubcategories = ref<Category[]>();

// const { data } = await useAsyncData("collections-and-categories", async () => {
// 	const [categories, collections] = await Promise.all([
// 		listCategoriesForShowroom(organizationProfile.value.organization_id),
// 		listCollections(organizationProfile.value.organization_id),
// 	]);

// 	return {
// 		// categories,
// 		// collections,
// 	};
// });

const showSubcategory = (item: Category | null) => {
	if (item) {
		activeSubcategories.value = item.subcategories;
	} else {
		activeSubcategories.value = [];
	}
};

// Search
// const searchInput = ref("");
// function onSearchUpdate() {}

// const searchResults = ref([]);

const open = ["Products"];

const toggleMenuMobile = () => {
	$bus.emit("toggleMenu");
};

const logoUrl = ref("");
const { getPlatformSettingsPlatform } = useECMApi();
const { imgixEcmWpcDomain } = useRuntimeConfig().public;

watchEffect(async () => {
	if (organizationProfile.value?.organization_id) {
		const { response } = await getPlatformSettingsPlatform(organizationProfile.value.organization_id);
		logoUrl.value = `https://${imgixEcmWpcDomain}/${(response?.data as any).platform_settings?.[0]?.id}/${
			(response?.data as any).platform_settings?.[0]?.platform_branding?.logo
		}`;
	}
});

/* const categories = [
	{
		id: "all_products",
		label: "components.navigations.label.all_products",
		url: "/products",
	{
		id: "category_name1",
		label: "components.navigations.label.category_name1",
		subcategory: [
			{
				name: "subcategory 11",
				path: "/category_name1/subcategory11",
			},
			{
				name: "subcategory 12",
				path: "/category_name1/subcategory12",
			},
			{
				name: "subcategory 13",
				path: "/category_name1/subcategory13",
			},
		],
	},
]; */
</script>
<style lang="scss" scoped>
.nav_bar_wrapper {
	@apply h-[72px] w-full fixed top-[30px] mobile:top-0 mobile:h-[120px];
	.nav_bar {
		@apply h-[72px] px-[40px] flex text-neutral-900 max-w-[1440px] mx-auto;
		@apply mobile:p-[15px] mobile:h-[120px] mobile:flex-col;
		.nav_bar_content {
			@apply flex w-full mobile:h-[50px];

			.flex_grow {
				@apply flex flex-1 items-center;
			}

			.left_items {
				@apply gap-x-[40px] mobile:gap-x-[5px] tablet:gap-x-[5px];
				div {
					@apply flex items-center;
				}
			}
			.right_items {
				@apply gap-x-[32px];
				div {
					@apply flex items-center;
				}
			}

			.left_items {
				@apply justify-start gap-x-[40px] mobile:gap-x-[5px] tablet:gap-x-[5px];

				#menu_icon {
					@apply hidden mobile:inline-block;
				}

				.left_content {
					@apply mobile:hidden;
				}
			}

			.right_items {
				@apply justify-end;

				.right_content {
					@apply mobile:hidden;
				}
			}

			.middle_items {
				@apply justify-center;
			}
		}

		.mobile_search {
			@apply hidden mobile:block;
		}

		#desktop_text {
			@apply tablet:hidden;
		}
	}
}

.v-btn--variant-flat {
	&:focus {
		@apply shadow-none;
		:deep(.v-btn__underlay) {
			@apply border-none rounded-[4px];
		}
	}
}

.result_item {
	@apply flex justify-between items-center mb-[10px] px-[24px];

	.image_name {
		@apply flex items-center gap-[12px];

		.image {
			@apply w-[60px] h-[40px] rounded;
		}

		.name {
			@apply text-neutral-900;
		}
	}
	.price {
		@apply text-neutral-900 text-[14px];
	}
}

#search_result_list {
	.v-list-item--density-default:not(.v-list-item--nav).v-list-item--one-line {
		@apply px-0;
	}
}

.main_menu_card {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	@apply flex;

	div {
		@apply w-[50%];

		.v-list-item {
			@apply w-full;

			&:hover {
				@apply bg-neutral-50;
			}
		}
	}

	.subcategory {
		@apply bg-neutral-50 py-[8px];
		.v-list-item {
			@apply px-[20px] h-[48px];
		}
	}
}
.menu_card {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
	@apply fixed top-[-104px] pt-[20px];
}

.menu_category {
	:deep(.v-list-item__content) {
		@apply flex items-center;
	}
}

.org_title {
	font-size: xx-large;
	font-family: "Lato";
}
</style>
