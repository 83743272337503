<template>
	<v-dialog
		:model-value="props.modelValue"
		:width="props?.width || 'auto'"
		scroll-strategy="none"
		@click:outside="onClickClose"
	>
		<div class="modal-content">
			<div
				v-if="props?.closable"
				class="close_icon"
			>
				<Icon
					class="cursor-pointer"
					name="md-close"
					@click="onClickClose"
				/>
			</div>
			<div class="dialog_content">
				<div class="logo">
					<svg
						width="152"
						height="80"
						viewBox="0 0 152 80"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M133.73 22.721C125.838 22.721 119.413 28.9086 118.997 36.6834H95.5101C94.7152 17.2041 78.6947 1.63575 58.9837 1.63575C56.107 1.63575 53.325 1.99473 50.647 2.62766C46.6915 1.22009 42.4617 0.417114 38.0331 0.417114C17.1771 0.417114 0.210327 17.3552 0.210327 38.176C0.210327 58.9967 17.1771 75.9348 38.0331 75.9348C42.4617 75.9348 46.701 75.1318 50.647 73.7243C53.325 74.3478 56.107 74.7162 58.9837 74.7162C79.0448 74.7162 95.3208 58.5999 95.5668 38.6294H119.025C119.621 46.2246 125.971 52.2045 133.73 52.2045C141.887 52.2045 148.502 45.6011 148.502 37.458C148.492 29.3149 141.887 22.721 133.73 22.721ZM38.0236 73.4975C18.5114 73.4975 2.64226 57.6553 2.64226 38.176C2.64226 18.6967 18.5114 2.85439 38.0236 2.85439C40.9192 2.85439 43.7108 3.24171 46.4077 3.89354C61.8793 7.66281 73.4145 21.5874 73.4145 38.176C73.4145 54.7645 61.8793 68.6797 46.4171 72.4584C43.7108 73.1102 40.9192 73.4975 38.0236 73.4975Z"
							fill="#B4B4B9"
						/>
					</svg>
				</div>
				<div class="title">This page is not available for now</div>
				<div class="description">Please speak to one of our representatives or book a virtual demo.</div>
				<div
					v-if="props?.hasButton"
					class="button"
				>
					<Button
						color="secondary"
						width="140"
					>
						<span class="ml-[8px]">Book a demo</span>
						<Icon name="md-chevron_right" />
					</Button>
				</div>
			</div>
		</div>
	</v-dialog>
</template>

<script lang="ts" setup>
import Button from "./Button.vue";

interface ModalProps {
	modelValue: boolean;
	closable?: boolean;
	width?: string | number;
	hasButton?: boolean;
}

const props = defineProps<ModalProps>();

const emits = defineEmits(["update:modelValue"]);
const onClickClose = () => emits("update:modelValue", false);
</script>

<style lang="scss" scoped>
.modal-content {
	@apply bg-white p-[56px] rounded-[8px];

	.close_icon {
		@apply absolute top-[24px] right-[24px];
	}

	.dialog_content {
		@apply flex flex-col justify-center;

		.logo,
		.title,
		.description {
			@apply mx-auto;
		}

		.logo {
			@apply mb-[24px];
		}

		.title {
			@apply text-[32px] leading-[40px] text-neutral-500 mb-[16px];
		}

		.description {
			@apply text-[16px] leading-[24px] text-neutral-500;
		}

		.button {
			@apply flex justify-center mt-[24px];
		}
	}
}
</style>
